<div class='chalmers-navigation-section charmers-section'>
  <div class='container chalmers-content-width'>
    <div class='row d-flex justify-content-between'>

      <a class="d-flex" href="https://www.chalmers.se/utbildning/examen-och-karriar/"  target="_blank"><img src="/img/chalmeresavancez.png" alt="Brand" class="chalmers-logotype" height="48" /></a>

      <h1 class="chalmers-h1 h1">{{ mainHeader | translate }}</h1>

      <a class="d-flex align-items-center" role="button" style="font-weight:bold" (click)="translate.activeLang = getOtherLanguage" >
        <img [src]="getImageURL" width="19" height="19" align="middle" hspace="5" />{{"language" | translate}}
      </a>
      <span class="d-flex align-items-center" role="button" style="font-weight:bold" *ngIf="!showLanguage">
        &nbsp;
      </span>
    </div>
  </div>
</div>
<div class='chalmers-login-section charmers-section'>
  <div class='container chalmers-content-width'>
    <div class='row mb-5'>
      <div *ngIf="student && student.fornamn" class="col-12">
        {{ "loggedInAs" | translate }} {{ student.fornamn }} {{ student.efternamn }}
      </div>
    </div>
  </div>
</div>
<div class='charmers-section chalmers-background'>
  <div class='container chalmers-content-width'>
    <div class='row  mb-5'>
      <div class='col-12 body-content'>
        <div class="container chalmers-w100" *ngIf="authenticated">
          <router-outlet name="authenticated"></router-outlet>
        </div>
        <div *ngIf="!authenticated" class="container">
          <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
