import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Arende } from '../../model/arende.model';
import { Ansokan } from '../../model/ansokan.model';
import { TranslateService } from '@ngstack/translate';
import { BaseUrlService } from '../base-url.service';

@Component({
  selector: 'app-step7',
  templateUrl: './step7.component.html',
  styleUrls: ['./step7.component.css']
})

export class Step7Component {
  baseUrl: string;
  public arendenummer: string;
  public message: string;
  public activeLanguage: string;

  constructor(public translate: TranslateService, private http: HttpClient,
    /*@Inject('BASE_URL') private baseUrl: string, */
    baseUrlService: BaseUrlService,
    private router: Router) {

    this.baseUrl = baseUrlService.getBaseUrl();
    this.activeLanguage = translate.activeLang;
    this.http.get<Arendenummer>('api/' + this.translate.activeLang + '/student/senastearendenummer')
      .subscribe(arende => this.arendenummer = arende.arendenummer);
  }

  public close() {
    open(location + '#', '_self').close();
  }
}

export interface Arendenummer {
  arendenummer: string;
}
