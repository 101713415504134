<step-tracker [stepIdx]="2" [lastStep]="7">
  <div *ngIf="notLoaded" style="color:#aaa">
    <div class="spinner-border mr-2" role="status">
    </div> {{ "Loading" | translate}}
  </div>
  <div class="row" *ngIf="(!allaKurserBetalda()) && loaded">
    <div class="col text-danger font-weight-bold">
      {{ 's3-karen' | translate }}
    </div>
  </div>
  <div class="row" *ngIf="terminer.length == 0 && loaded">
    <div class="col text-danger font-weight-bold">
      {{ 's3-noSemesters' | translate }}
    </div>
  </div>
  <div class="row" *ngIf="loaded">
    <div class="col">
      <h2 class="chalmers-h2">{{ "s3-header" | translate }}</h2>
      <h3 class="chalmers-h3">{{ "s3-header1" | translate }}</h3>
      <p>
        {{ "s3-info" | translate }}
      </p>
      <h3 class="chalmers-h3">{{ "s3-header2" | translate }}</h3>
      <p>
        {{ "s3-info2" | translate }}
      </p>
      <p>
        <a target="_blank" href="{{ 's3-link-faq-url' | translate }}">{{ "s3-link-faq" | translate }}</a>
      </p>
      <button (click)="selectAlla()" class="btn btn-success chalmers-mr-2">{{ "s3-chooseBtn" | translate }}</button>
      <button (click)="unselectAlla()" class="btn btn-danger chalmers-mr-2">{{ "s3-removeBtn" | translate }}</button>
    </div>
  </div>
  <div class="row mt-3" *ngIf="loaded">
    <div class="col">
      <h3 class="chalmers-h3">{{ 's3-subHeader' | translate }}</h3>
    </div>
    <div class="col">
      <strong>{{ "s3-points" | translate }}</strong> {{sumValdaPoang}}
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="container">
        <div *ngFor="let kurs of kurser" class="chalmers-kurs {{isSelected(kurs) ? 'chalmers-kurs-selected' : ''}} {{kursBetald(kurs) ? '' : 'bg-danger text-white'}} row" (click)="onClick(kurs)">
          <div class="col">
            <strong>{{kurs.namn}}</strong> {{kurs.kursomfattning}} hp<br />
            <small><em>{{ "code" | translate }} {{kurs.kurskod}} {{ "university" | translate }} {{kurs.larosate}}</em></small><br />
            <small><em>{{ "examinationDate" | translate }} {{kurs.examinationsdatum | date:'yyyy-MM-dd'}}</em></small><br />
          </div>
          <div class="col text-right" *ngIf="!kursBetald(kurs)">
            <strong>{{ 's3-karen' | translate }}</strong><br />
            <small><em>{{kurs.start | date:'yyyy-MM-dd'}} - {{kurs.slut | date:'yyyy-MM-dd'}}</em></small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h3 *ngIf="tillgodoraknanden.length > 0">{{ 's3-credits' | translate }}</h3>
      <div *ngFor="let tillgodoraknande of tillgodoraknanden" class="chalmers-kurs {{isSelectedTillgodoraknande(tillgodoraknande) ? 'chalmers-kurs-selected' : ''}}" (click)="onClickTillgodoraknande(tillgodoraknande)">
        <div class="col">
          <span>{{ 's3-credited-as' | translate }}</span><br />
          <strong>{{tillgodoraknande.benamning}}</strong> {{tillgodoraknande.omfattning}} hp<br />
          <small><em>{{ 's3-decision-date' | translate }}: {{tillgodoraknande.beslutsdatum}}, {{ 's3-decided-by' | translate }}: {{tillgodoraknande.beslutsfattare}}</em></small>
          <div>{{ 's3-based-on' | translate }}</div>
          <div *ngFor="let grund of tillgodoraknande.tillgodogrunder">
            <div>{{grund.namn}}</div>
            <small><em>{{ 's3-Source' | translate }}: {{grund.ursprung}}</em></small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row chalmers-buttons" *ngIf="loaded">
    <div class="col">
      <a class="btn btn-danger chalmers-button chalmers-mr-2" role="button" [routerLink]="['', { outlets: { authenticated: ['step2'] } }]" routerLinkActive="active">{{ "last" | translate }}</a>
      <button type="button" class="btn btn-success chalmers-button" role="button" [disabled]="(!allaKurserBetalda()) || (terminer.length == 0)" [routerLink]="['', { outlets: { authenticated: ['step4'] } }]" routerLinkActive="active">{{ "next" | translate }}</button>
    </div>
  </div>
</step-tracker>
