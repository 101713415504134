import { Component, Input } from '@angular/core';

@Component({
  selector: 'step-tracker',
  templateUrl: './step-tracker.component.html',
  styleUrls: ['./step-tracker.component.css']
})

export class StepTrackerComponent {

  public get steps(): string[] {
    let arr: string[] = [];
    for (var i = 1; i <= this.lastStep; i++) {
      arr.push(i.toString());
    }
    return arr;
  }

  @Input("stepIdx")
  public stepIdx: number;
  @Input("lastStep")
  public lastStep: number;
  constructor() {
  }
}
