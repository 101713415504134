import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Kurs } from '../../model/kurs.model';
import { Ansokan, Bevisansokan } from '../../model/ansokan.model';
import { Bevisinformation } from '../../model/bevisinformation.model';
import { Precisering } from '../../model/precisering.model';
import { Arende } from '../../model/arende.model';
import { TranslateService } from '@ngstack/translate';
import { Tillgodoraknande } from '../../model/tillgodoraknande.model';
import { BaseUrlService } from '../base-url.service';

@Component({
  selector: 'app-step6',
  templateUrl: './step6.component.html',
  styleUrls: ['./step6.component.css']
})

export class Step6Component {
  baseUrl: string;
  valdBeviskombination: Bevisinformation;
  valdPrecisering: Precisering;
  valdaKurser: Kurs[];
  valdaTillgodoraknanden: Tillgodoraknande[];
  friText: string;
  public bilagor: string[];
  public sending: boolean;
  public get notSending(): boolean {
    return !this.sending;
  }
  constructor(public translate: TranslateService, private http: HttpClient,
    /*@Inject('BASE_URL') private baseUrl: string,*/
    baseUrlService: BaseUrlService,
    private router: Router) {
    this.baseUrl = baseUrlService.getBaseUrl();
    this.valdaKurser = Ansokan.kurser;
    this.valdaTillgodoraknanden = Ansokan.tillgodoraknanden;
    this.valdBeviskombination = Ansokan.beviskombination;
    this.valdPrecisering = Ansokan.precisering;

    if (this.translate.activeLang == "en") {
      if (this.valdBeviskombination.preciseringsNamn == "Examensinriktning")
        this.valdBeviskombination.preciseringsNamn = "Main Field of Study";
      else if (this.valdBeviskombination.preciseringsNamn == "Huvudområde")
        this.valdBeviskombination.preciseringsNamn = "Major Subject";
      else if (this.valdBeviskombination.preciseringsNamn == "Examensämne på forskarnivå")
        this.valdBeviskombination.preciseringsNamn = "Third-cycle degree subject";
    }
    this.friText = Ansokan.meddelande;
    this.bilagor = Ansokan.bilagor;
    this.sending = false;
  }

  public skicka(): void {
    let bevisansokan = Ansokan.create();
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    this.sending = true;
    this.http.post<AnsokanUid>(this.baseUrl + "api/" + this.translate.activeLang + "/ansokan", bevisansokan, { headers })
      .subscribe(value => {
        Ansokan.uid = value.uid;
        this.router.navigate(['', { outlets: { authenticated: ['step7'] } }]);
        this.sending = false;
      }, error => {
        console.error(error);
        this.sending = false;
      });
  }
}

export interface AnsokanUid {
  uid: string;
}
