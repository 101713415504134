<step-tracker [stepIdx]="4" [lastStep]="7">
  <div class="row">
    <div class="col-12">
      <h2 class="chalmers-h2">{{ "s5-header" | translate }}</h2>
      <p>
        {{ "s5-info" | translate }}
      </p>
      <textarea class="chalmers-w100" style="height:400px" [(ngModel)]="friText"></textarea>
    </div>
  </div>
  <div class="row chalmers-buttons">
    <div class="col-12">
      <a class="btn btn-danger chalmers-button chalmers-mr-2" role="button" [routerLink]="['', { outlets: { authenticated: ['step4'] } }]" routerLinkActive="active">{{ "last" | translate }}</a>
      <a class="btn btn-success chalmers-button" style="color:white" role="button" (click)="onNext()">{{ "next" | translate }}</a>
    </div>
  </div>
</step-tracker>

