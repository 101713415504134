<step-tracker [stepIdx]="1" [lastStep]="6">
  <div *ngIf="notLoaded" style="color:#aaa">
    <div class="spinner-border mr-2" role="status">
    </div> {{ "Loading" | translate}}
  </div>
  <div *ngIf="loaded">
    <div class="row">
      <div class="col-12">
        <h2 class="chalmers-h2">{{ "ubs2-header" | translate }}</h2>
        <h3 class="chalmers-h3">{{ "ubs2-header2" | translate }}</h3>
        <p>
          {{ "ubs2-info2" | translate }}
        </p>
        <p>
          <a target="_blank" href="{{ 'ubs2-link-faq-url' | translate }}">{{ "ubs2-link-faq" | translate }}</a>
        </p>
        <h3 class="chalmers-h3">{{ "ubs2-header3" | translate }}</h3>
        <p>
          {{ "ubs2-info3" | translate }}
        </p>
        <p>
          <a target="_blank" href="{{ 'ubs2-link-lic-url' | translate }}">{{ "ubs2-link-lic" | translate }}</a><br />
          <a target="_blank" href="{{ 'ubs2-link-doc-url' | translate }}">{{ "ubs2-link-doc" | translate }}</a>
        </p>
        <h3 class="chalmers-h3">{{ "ubs2-header4" | translate }}</h3>
        <p>
          {{ "ubs2-info4" | translate }}
        </p>
        <select id="bevistyper" class="form-control" (change)="onChange_bevistyper($event.target.value)">
          <option>{{ "ubs2-choice" | translate }}</option>
          <option *ngFor="let bevis of bevisinformation" value="{{bevis.id}}" [selected]="bevis.id == valdBevisinformation">{{bevis.namn}}</option>
        </select>
      </div>
    </div>
    <div class="row" *ngIf="showPreciseringar">
      <div class="col-12">
        <p>
          {{ "s2-mainArea" | translate }}
        </p>
        <h4 class="chalmers-h4">{{preciseringsNamn}} {{ "ubs2-mainAreaHeader" | translate }}</h4>
        <select id="preciseringar" class="form-control" (change)="onChange_preciseringar($event.target.value)">
          <option [selected]="harIngenValdPrecisering" disabled="disabled">{{ "ubs2-choicePrecisering" | translate }}</option>
          <option *ngFor="let precisering of preciseringar" value="{{precisering.id}}" [selected]="precisering.id == valdPrecisering">{{precisering.namn}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p>{{ "ubs2-info5" | translate }}</p>
      </div>
    </div>
    <div class="row chalmers-buttons">
      <div class="col-12">
        <a class="btn btn-danger chalmers-button chalmers-mr-2" role="button" [routerLink]="['', { outlets: { authenticated: ['ub','step1'] } }]"  routerLinkActive="active">{{ "last" | translate }}</a>
        <button [disabled]="!isValid" class="btn btn-success chalmers-button" role="button" [routerLink]="['', { outlets: { authenticated: ['ub','step3'] } }]"  routerLinkActive="active">{{ "next" | translate }}</button>
      </div>
    </div>
  </div>
</step-tracker>

