import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Kurs } from '../../model/kurs.model';
import { Ansokan, Bevisansokan } from '../../model/ansokan.model';
import { Bevisinformation } from '../../model/bevisinformation.model';
import { Precisering } from '../../model/precisering.model';
import { Arende } from '../../model/arende.model';
import { TranslateService } from '@ngstack/translate';
import { Tillgodoraknande } from '../../model/tillgodoraknande.model';
import { BaseUrlService } from '../base-url.service';


@Component({
  selector: 'app-ubstep5',
  templateUrl: './ubstep5.component.html',
  styleUrls: ['./ubstep5.component.css']
})
/** ubstep5 component*/
export class Ubstep5Component {
  baseUrl: string;
  valdBeviskombination: Bevisinformation;
  valdPrecisering: Precisering;
  valdaKurser: Kurs[];
  valdaTillgodoraknanden: Tillgodoraknande[];
  friText: string;
  public sending: boolean;
  public get notSending(): boolean {
    return !this.sending;
  }
  constructor(public translate: TranslateService, private http: HttpClient,
    /*@Inject('BASE_URL') private baseUrl: string,*/
    baseUrlService: BaseUrlService, private router: Router) {
    this.valdaKurser = Ansokan.kurser;
    this.valdaTillgodoraknanden = Ansokan.tillgodoraknanden;
    this.valdBeviskombination = Ansokan.beviskombination;
    this.valdPrecisering = Ansokan.precisering;
    this.friText = Ansokan.meddelande;
    this.sending = false;
    this.baseUrl = baseUrlService.getBaseUrl();
  }

  public skicka(): void {
    let bevisansokan = Ansokan.create();
    console.debug(JSON.stringify(bevisansokan));
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    this.sending = true;
    this.http.post<AnsokanUid>(this.baseUrl + "api/" + this.translate.activeLang + "/ansokan", bevisansokan, { headers })
      .subscribe(value => {
        Ansokan.uid = value.uid;
        this.router.navigate(['', { outlets: { authenticated: ['ub','step6'] } }]);
        this.sending = false;
      }, error => {
        console.error(error);
        this.sending = false;
      });
  }
}
export interface AnsokanUid {
  uid: string;
}
