import { Component, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Student } from '../../model/student.model';
import { TranslateService } from '@ngstack/translate';
import { Subscription } from 'rxjs';
import { Ansokan } from '../../model/ansokan.model';
import { BaseUrlService } from '../base-url.service';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})

export class Step1Component {
  student: Student;
  get hasAddress(): boolean {
    return this.student != null && this.student.postadress != null;
  }
  authenticated: boolean;

  baseUrl: string;
  http: HttpClient;
  subscription: Subscription;
  public returnUrl: string;
  public notLoaded: boolean;
  public get loaded(): boolean {
    return !this.notLoaded;
  }

  get showOngoingApplications(): boolean {
    return this.student && this.student.arenden && this.student.arenden.length > 0;
  }

  constructor(public translate: TranslateService, http: HttpClient, baseUrlService: BaseUrlService
    /*@Inject('BASE_URL') baseUrl: string*/) {
    this.baseUrl = baseUrlService.getBaseUrl();
    this.http = http;
    this.returnUrl = location.pathname + (location.search > '' ? location.search : '');
    console.log(this.returnUrl);
    this.getAuthenticated(http, this.baseUrl);

    this.subscription = translate.activeLangChanged.subscribe(
      (event: { previousValue: string; currentValue: string }) => {
        this.getStudent(this.http, this.baseUrl, event.currentValue);
      }
    );
  }
  
  ngOnInit() {
    this.getStudent(this.http, this.baseUrl, this.translate.activeLang);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getAuthenticated(http: HttpClient, baseUrl: string) {
      this.notLoaded = true;
      http.get<boolean>(baseUrl + 'api/login/authenticated').subscribe(result => {
        this.authenticated = result;
        if (this.authenticated)
          this.notLoaded = true;
        else
          this.notLoaded = false;
      }, error => {
          console.error(error);
          this.notLoaded = false;
      });
  }

  private getStudent(http: HttpClient, baseUrl: string, language: string) {
    this.notLoaded = true;
    http.get<Student>(baseUrl + 'api/' + language + '/Student').subscribe(result => {
      this.student = result;
      this.notLoaded = false;
    }, error => {
      console.error(error);
      this.notLoaded = false;
    });

    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    http.get<DirectoryResult>(baseUrl + 'api/file/directory').subscribe(result => {
      console.log(result.directory);
      Ansokan.bilagorMapp = result.directory;
    }, error => {
      console.error(error);
    });

  }
}

export interface DirectoryResult
{
  directory: string;
}
