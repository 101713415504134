import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngstack/translate';
import { Student } from '../model/student.model';
import { IDP } from '../model/idp.model';
import { ActivatedRoute, Router } from '@angular/router';
//import { stringify } from '@angular/compiler/src/util';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators'
import { Title } from '@angular/platform-browser';
import { BaseUrlService } from './base-url.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  baseUrl: string;
  student: Student;
  imageUrl: string = "/assets/images/{language}.png";
  public href: string = "";
  mainHeader: string;
  showLanguage: boolean;
  error: string;
  identityProviders: IDP[] =[];
  get returnUrl(): string {
    console.log(location.pathname + '?language=' + this.translate.activeLang)
    return location.pathname + '?language=' + this.translate.activeLang;
  }

  subscription: Subscription;

  public authenticated: boolean;
  constructor(
    public translate: TranslateService,
    http: HttpClient, baseUrlService: BaseUrlService
    /*@Inject('BASE_URL') baseUrl: string*/,
    private route: ActivatedRoute,
    private titleService: Title) {
    this.baseUrl = baseUrlService.getBaseUrl();
    this.getAuthenticated(http, this.baseUrl);
    this.setMainHeader();
    this.getLanguageFromQueryString();
    this.getStudent(http, this.baseUrl);
    this.setTitle();
    this.getIdps(http, this.baseUrl);

    this.route.queryParams.subscribe(params => {
      if( params['error'] == '1') this.error = "errorMessage1"
      else if( params['error'] == '2') this.error = "errorMessage2"

    });

    this.subscription = translate.activeLangChanged.subscribe(
      (event: { previousValue: string; currentValue: string }) => {
        this.setTitle();
      }
    );
  }

  private setTitle() {
    if (location.pathname.toLowerCase().includes("ub")) {
      this.titleService.setTitle(this.translate.get("ub-main-header"));
      console.log(true);
    }
    else {
      this.titleService.setTitle(this.translate.get("main-header"));
    }
  }

  private getStudent(http: HttpClient, baseUrl: string) {
    http.get<Student>(baseUrl + 'api/' + this.translate.activeLang + '/Student').subscribe(result => {
      this.student = result;
    }, error => console.error(error));
  }

  private getIdps(http: HttpClient, baseUrl: string) {
    http.get<IDP[]>(baseUrl + 'api/idp').subscribe(result => {
      this.identityProviders = result;
    }, error => console.error(error));
  }

  private getLanguageFromQueryString() {
    this.route.queryParams.subscribe(params => {
      let language: string = params['language'];
      if (language == "sv" || language == "en") {
        this.translate.activeLang = language;
      }
    });
  }

  private setMainHeader() {
    if (location.pathname.toLowerCase().includes("ub")) {
      this.mainHeader = "ub-main-header";
      this.showLanguage = false;
    }
    else {
      this.mainHeader = "main-header";
    }
  }


  get getOtherLanguage(): string {
    return this.translate.activeLang === "sv" ? "en" : "sv";
  }

  get getImageURL(): string {
    return this.imageUrl.replace("{language}", this.translate.activeLang === "sv" ? "en" : "sv");
  }

  private getAuthenticated(http: HttpClient, baseUrl: string) {

    http.get<boolean>(baseUrl + 'api/login/authenticated').subscribe(result => {
      this.authenticated = result;
    }, error => {
      console.error(error);
    });
  }
  ngOnInit() {
  }
}
