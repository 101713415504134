import { Component, Inject, SimpleChanges, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Bevisinformation } from '../../model/bevisinformation.model';
import { Precisering } from '../../model/precisering.model';
import { Ansokan } from '../../model/ansokan.model';
import { TranslateService } from '@ngstack/translate';
import { Subscription } from 'rxjs';
import { BaseUrlService } from '../base-url.service';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})

export class Step2Component implements OnInit, OnDestroy {
  bevisinformation: Bevisinformation[];
  preciseringar: Precisering[];
  preciseringsNamn: string;
  baseUrl: string;
  http: HttpClient;
  subscription: Subscription;

  get valdBevisinformation(): string {
    if (Ansokan.beviskombination != null) {
      return Ansokan.beviskombination.id;
    } else {
      return null;
    }
  }

  get valdPrecisering(): string {
    if (Ansokan.precisering != null) {
      return Ansokan.precisering.id;
    } else {
      return null;
    }
  }

  get isValid(): boolean {
    return (Ansokan.beviskombination != null && Ansokan.beviskombination.perciseringar.length == 0)
      || (Ansokan.beviskombination != null && Ansokan.beviskombination.perciseringar.length > 0 && Ansokan.precisering.id > '');
  }

  get harIngenValdPrecisering(): boolean {
    return Ansokan.precisering == null
      || !Ansokan.precisering.id
      || Ansokan.precisering.id == null
      || Ansokan.precisering.id == '';
  }

  get showPreciseringar(): boolean {
    return this.preciseringar.length > 0;
  }

  public notLoaded: boolean;
  public get loaded(): boolean {
    return !this.notLoaded;
  }

  constructor(public translate: TranslateService, http: HttpClient, baseUrlService: BaseUrlService
    /*@Inject('BASE_URL') baseUrl: string*/) {

    this.subscription = translate.activeLangChanged.subscribe(
      (event: { previousValue: string; currentValue: string }) => {
        this.bevisinformation.splice(0);
        this.preciseringar.splice(0);
        Ansokan.beviskombination = null;
        this.getBevisInformation(this.http, this.baseUrl, event.currentValue);
      }
    );

    this.preciseringar = new Array(0);
    this.baseUrl = baseUrlService.getBaseUrl();
    this.http = http;
  }

  ngOnInit() {
    this.getBevisInformation(this.http, this.baseUrl, this.translate.activeLang);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getBevisInformation(http: HttpClient, baseUrl: string, language: string) {
    this.notLoaded = true;
    http.get<Bevisinformation[]>(baseUrl + 'api/' + language + '/bevisinformation').subscribe(result => {
      this.bevisinformation = result;
      if (this.valdBevisinformation) {
        this.preciseringar = this.getPreciseringar(this.valdBevisinformation);
      }
      else {

      }
      this.notLoaded = false;
    }, error => {
      console.error(error);
      this.notLoaded = false;
    });
  }

  onChange_bevistyper(id: string): void {
    this.preciseringar = this.getPreciseringar(id);
    Ansokan.beviskombination = this.bevisinformation.find(r => r.id == id);
    Ansokan.kurser = [];
    Ansokan.precisering = new Precisering();
  }

  onChange_preciseringar(id: string) {
    Ansokan.precisering = this.preciseringar.find(p => p.id == id);
  }

  private getPreciseringar(id: string): Precisering[] {
    const foundBevisinformation = this.bevisinformation.find(r => r.id == id);

    if (foundBevisinformation != null) {
      this.preciseringsNamn = foundBevisinformation.preciseringsNamnPlural;
      if (this.translate.activeLang == "en") {
        if (foundBevisinformation.preciseringsNamnPlural == "Examensinriktningar")
          this.preciseringsNamn = "Degree specialisations";
        else if (foundBevisinformation.preciseringsNamnPlural == "Huvudområden")
          this.preciseringsNamn = "Main fields of study";
        else if (foundBevisinformation.preciseringsNamnPlural == "Examensämnen på forskarnivå")
          this.preciseringsNamn = "Third-cycle degree subject";
      }
      return foundBevisinformation.perciseringar;
    }
    else {
      return new Array(0);
    }
  }
}
