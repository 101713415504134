<step-tracker [stepIdx]="0" [lastStep]="6">
  <div *ngIf="notLoaded" style="color:#aaa">
    <div class="spinner-border mr-2" role="status">
    </div> {{ "Loading" | translate}}
  </div>
  <div *ngIf="authenticated && loaded" class="row">
    <div class="col" *ngIf="student">
      <h2 class="chalmers-h2">{{ "ubs1-header" | translate }}</h2>
      <h3 class="chalmers-h3">{{ "ubs1-header2" | translate }}</h3>
      <p>
        {{ "ubs1-info2" | translate}}
      </p>
      <p>
        {{ "ubs1-name" | translate}}<br />
        <strong>{{student.efternamn}}, {{student.fornamn}}</strong><br />
        {{ "ubs1-pnr" | translate}} <strong>{{student.personnummer}}</strong>
      </p>
      <p>
        <a target="_blank" href="{{'ubs1-faq-url' | translate}}">{{"ubs1-faq" | translate}}</a>
      </p>
      <p>
        {{ "ubs1-communication" | translate }}<br />
        <strong>{{student.epostAdress}}</strong><br />
        <strong>{{student.telefonnummer}}</strong>
      </p>
      <p>
        {{ "ubs1-email" | translate }}<br />
        <strong>
          {{student.postadress.utdelningsadress}}<br />
          {{student.postadress.postnummer}} {{student.postadress.postort}}
        </strong>
      </p>
      <p>
        <a target="_blank" href="{{ 'ubs1-change-url' | translate}}" rel="noopener">{{ "ubs1-change" | translate}}</a>
      </p>
    </div>
  </div>
  <div class="row" *ngIf="showOngoingApplications && authenticated && loaded">
    <div class="col">
      <h3 class="chalmers-h3">{{ "ubs1-arrends" | translate }}</h3>
      <div class="mt-2 pt-2 border-top" *ngFor="let arende of student.arenden">
        <div class="small">
          <span class="text-capitalize">{{arende.bevisTyp}}</span><br />
          <span>{{arende.precisering}}</span>
        </div>
        <div class="small font-italic mt-2" style="white-space: nowrap">
          <span class="small">{{arende.inkommet | date:'yyyy-MM-dd'}}</span>
          <span class="small pl-3">{{arende.arendenummer}}</span>
          <span class="small pl-3">{{arende.status}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row chalmers-buttons" *ngIf="authenticated && loaded">
    <div class="col-12">
      <a class="btn btn-success chalmers-button" role="button" [routerLink]="['', { outlets: { authenticated: ['ub','step2'] } }]" routerLinkActive="active">{{ "next" | translate }}</a>
    </div>
  </div>
</step-tracker>
