import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Ansokan } from '../../model/ansokan.model';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.css']
})
/** steg4 component*/
export class Step5Component {
  /** steg4 ctor */
  friText: string;
  allSemestersPaid: boolean;
  constructor(private router: Router) {
    this.friText = Ansokan.meddelande;
  }
  onNext() {
    Ansokan.meddelande = this.friText;
    console.debug(JSON.stringify(Ansokan));
    this.router.navigate(['', { outlets: { authenticated: ['step6'] } }]);
  }
}
