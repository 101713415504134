import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDP } from '../../model/idp.model';
import { BaseUrlService } from '../base-url.service';
import { TranslateService } from '@ngstack/translate';

@Component({
  selector: 'Home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  welcomeTitle: string = "Välkommen";
  welcomeMessage: string;
  showUbMessage: boolean = false;
  error: string;
  identityProviders: IDP[] = [];
  authenticated: boolean;

  baseUrl: string;
  http: HttpClient;
  subscription: Subscription;

  public returnUrl: string;
  public notLoaded: boolean;
  constructor(public translate: TranslateService,
    private route: ActivatedRoute,
    http: HttpClient, baseUrlService: BaseUrlService
    /*@Inject('BASE_URL') baseUrl: string*/, router: Router) {
    this.returnUrl = location.pathname + (location.search > '' ? location.search : '');
    if (this.returnUrl.includes("ub")) {
      this.returnUrl = "/(authenticated:ub/step1)"
      this.showUbMessage = true;
      //this.welcomeMessage = translate.get('ub-welcome-message');
    }
    else {
      //else if (this.translate.activeLang == 'en') {
      //this.welcomeMessage = translate.get('welcome-message');
    }

    console.log(this.returnUrl);
    this.baseUrl = baseUrlService.getBaseUrl();
    this.getIdps(http, this.baseUrl);
    this.getAuthenticated(http, this.baseUrl, router);
    if (this.authenticated) {

    }
    console.log(this.authenticated)
    console.log(this.route.snapshot.url[0]?.path.includes("ub"))
  }

  private getAuthenticated(http: HttpClient, baseUrl: string, router: Router) {
    this.notLoaded = true;
    http.get<boolean>(baseUrl + 'api/login/authenticated').subscribe(result => {
      this.authenticated = result;
      if (this.authenticated) {
        this.notLoaded = true;
        if (this.route.snapshot.url[0]?.path.includes("ub"))
          router.navigate([{ outlets: { authenticated: ['ub', 'step1'] } }]);
      }
      else
        this.notLoaded = false;

    }, error => {
      console.error(error);
      this.notLoaded = false;
    });
  }

  private getIdps(http: HttpClient, baseUrl: string) {
    http.get<IDP[]>(baseUrl + 'api/idp').subscribe(result => {
      this.identityProviders = result;
      console.log(this.route.snapshot.url[0]?.path);
    }, error => console.error(error));
  }

  public openPolicyPage() {
    //window.open('../../assets/privacypolicy.html', '_blank');
    window.open(this.translate.get('privacy-policy-url'), '_blank');
  }
}
