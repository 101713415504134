import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Arende } from '../../model/arende.model';
import { Ansokan } from '../../model/ansokan.model';
import { TranslateService } from '@ngstack/translate';
import { AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { BaseUrlService } from '../base-url.service';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.css']
})
/** steg4 component*/
export class Step4Component {
  /** steg4 ctor */
  public activeLanguage: string;
  baseUrl: string;
  friText: string;
  allSemestersPaid: boolean;
  public progress: number;
  public message: string;
  public noPDF: boolean;
  public filesToUpload: File[];
  public enableUpload: boolean;
  uploadedFiles: string[] = [];
  @ViewChild('file') File: ElementRef;
  @Output() public onUploadFinished = new EventEmitter();

  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    baseUrlService: BaseUrlService,
    /*@Inject('BASE_URL') private baseUrl: string,*/
    private router: Router) {
    this.activeLanguage = translate.activeLang;
    this.baseUrl = baseUrlService.getBaseUrl();
    this.enableUpload = true;
    this.noPDF = false;
    this.uploadedFiles = Ansokan.bilagor;
  }
  get showUploadedFiles(): boolean {
    return Ansokan.bilagor && Ansokan.bilagor.length > 0;
  }

  public uploadFile = (files) => {
    console.debug('step1 upload');
    this.noPDF = false;
    if (files.length === 0) {
      return;
    }
    if (!files[0].name.toLowerCase().endsWith('.pdf')) {
      this.noPDF = true;
      return;
    }
    console.debug('step2 upload');
    this.filesToUpload = files;
    const formData = new FormData();

    console.debug('step3 upload');
    Array.from(this.filesToUpload).map((file, index) => {
      Ansokan.bilagor.push(file.name);
      this.uploadedFiles = Ansokan.bilagor;
      return formData.append('file' + index, file, file.name);
    });

    console.debug('step4 upload');
    this.http.post(this.baseUrl + 'api/file/' + Ansokan.bilagorMapp, formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response) {
          this.message = this.translate.activeLang == 'en' ? 'Upload success.' : 'Uppladdningen gick bra';
          this.onUploadFinished.emit(event.body);
        }
      });
  }

  onNext() {
    Ansokan.meddelande = this.friText;
    console.debug(JSON.stringify(Ansokan));
    this.router.navigate(['', { outlets: { authenticated: ['step5'] } }]);
  }

  deleteFile(file: string) {
    Ansokan.bilagor = Ansokan.bilagor.filter(b => b != file);
    this.uploadedFiles = Ansokan.bilagor;
    this.File.nativeElement.value = '';
  }
}

export interface UploadResult {
  File: string;
}
