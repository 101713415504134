import { Bevisinformation } from './bevisinformation.model';
import { Precisering } from './precisering.model';
import { Kurs } from './kurs.model';
import { Arende } from './arende.model';
import { Tillgodoraknande } from './tillgodoraknande.model';

export class Ansokan {
  public static beviskombination: Bevisinformation;
  public static doktorsEllerLicentiate(): boolean {
    if (Ansokan.beviskombination != null) {
      if (Ansokan.beviskombination.namn.toLowerCase().indexOf("doktorsexamen") > 0 ||
        Ansokan.beviskombination.namn.toLowerCase().indexOf("licentiatexamen") > 0)
        return true;
    }
    return false;
  }; //doktorsexamen licentiatexamen
  public static precisering: Precisering;
  public static kurser: Kurs[] = [];
  public static tillgodoraknanden: Tillgodoraknande[] = [];
  public static meddelande: string;
  public static sprak: string = "sv";
  public static arende: Arende;
  public static uid: string;
  public static bilagorMapp: string;
  public static bilagor: string[] = [];

  public static create(): Bevisansokan {
    let bevisansokan = new Bevisansokan();
    bevisansokan.beviskombinationUID = Ansokan.beviskombination.id;
    bevisansokan.larosateIDSpecified = false;
    bevisansokan.precisering = Ansokan.precisering.id != null ? [+Ansokan.precisering.id] : [];
    bevisansokan.fritext = Ansokan.meddelande;
    bevisansokan.resultat = Ansokan.kurser.map(k => k.id);
    bevisansokan.bilagorMapp = Ansokan.bilagorMapp;
    bevisansokan.bilagor = Ansokan.bilagor;
    if (Ansokan.tillgodoraknanden.length > 0)
      bevisansokan.resultat = bevisansokan.resultat.concat(Ansokan.tillgodoraknanden.map(t => t.id));
    return bevisansokan;
  }
}

export class Bevisansokan {
  beviskombinationUID: string;
  fritext: string;
  larosateIDSpecified: boolean;
  precisering: number[];
  resultat: string[];
  bilagorMapp: string;
  bilagor: string[] = [];
}
