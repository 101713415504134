<step-tracker [stepIdx]="4" [lastStep]="6">
  <div *ngIf="sending" style="color:#aaa">
    <div class="spinner-border mr-2" role="status">
    </div> {{ "ubs5-sending" | translate}}
  </div>

  <div class="row" *ngIf="notSending">
    <div class="col">
      <h2 class="chalmers-h2">{{ "ubs5-header" | translate}} </h2>
    </div>
  </div>
  <div class="row" *ngIf="notSending">
    <div class="col">
      <p> {{ "ubs5-information" | translate}} </p>
    </div>
  </div>
  <div class="row" *ngIf="notSending">
    <div class="col border-top pt-3">
      <h3 class="chalmers-h3">{{valdBeviskombination.namn}}</h3>
      <h4 class="chalmers-h4" *ngIf="valdPrecisering.id != null">{{valdBeviskombination.preciseringsNamn}}: {{valdPrecisering.namn}}</h4>
      <span *ngIf="friText != null">
        {{ "ubs5-message" | translate }}
        <br />
        {{friText}}
      </span>
    </div>
  </div>
  <div class="row" *ngIf="notSending">
    <div class="col">
      <div *ngFor="let kurs of valdaKurser" class="chalmers-kurs chalmers-kurs-selected">
        <strong>{{kurs.namn}}</strong> {{kurs.kursomfattning}} hp<br />
        <small><em>{{ "code" | translate }} {{kurs.kurskod}} {{ "university" | translate }} {{kurs.larosate}}</em></small><br />
        <small><em>{{ "examinationDate" | translate }} {{kurs.examinationsdatum | date:'yyyy-MM-dd'}}</em></small><br />
      </div>
    </div>
  </div>
  <div class="row" *ngIf="valdaTillgodoraknanden.length > 0 && notSending">
    <div class="col">
      <h3>{{ 's3-credits' | translate }}</h3>
      <div *ngFor="let tillgodoraknande of valdaTillgodoraknanden" class="chalmers-kurs chalmers-kurs-selected">
        <div class="col">
          <span>{{ 's3-credited-as' | translate }}</span><br />
          <strong>{{tillgodoraknande.benamning}}</strong> {{tillgodoraknande.omfattning}} hp<br />
          <small><em>{{ 's3-decision-date' | translate }}: {{tillgodoraknande.beslutsdatum}}, {{ 's3-decided-by' | translate }}: {{tillgodoraknande.beslutsfattare}}</em></small>
          <div>{{ 's3-based-on' | translate }}</div>
          <div *ngFor="let grund of tillgodoraknande.tillgodogrunder">
            <div>{{grund.namn}}</div>
            <small><em>{{ 's3-Source' | translate }}: {{grund.ursprung}}</em></small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row chalmers-buttons" *ngIf="notSending">
    <div class="col">
      <a class="btn btn-danger chalmers-button chalmers-mr-2" role="button" [routerLink]="['', { outlets: { authenticated: ['ub','step4'] } }]"  routerLinkActive="active">{{ "last" | translate }}</a>
      <a class="btn btn-success chalmers-button" style="color:white" role="button" (click)="skicka()">{{ "ubs5-apply" | translate }}</a>
    </div>
  </div>
</step-tracker>
