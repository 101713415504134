import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Student } from '../../model/student.model';
import { TranslateService } from '@ngstack/translate';
import { Subscription } from 'rxjs';
import { Ansokan } from '../../model/ansokan.model';
import { Router } from '@angular/router';
import { BaseUrlService } from '../base-url.service';

@Component({
  selector: 'app-ubstep1',
  templateUrl: './ubstep1.component.html',
  styleUrls: ['./ubstep1.component.css']
})
/** ubstep1 component*/
export class Ubstep1Component {
  student: Student;
  authenticated: boolean;

  baseUrl: string;
  http: HttpClient;
  subscription: Subscription;
    
  public returnUrl: string;
  public notLoaded: boolean;
  public get loaded(): boolean {
    return !this.notLoaded;
    
  }

  get showOngoingApplications(): boolean {
    return this.student && this.student.arenden && this.student.arenden.length > 0;
  }

  constructor(public translate: TranslateService, http: HttpClient,
    /*@Inject('BASE_URL') baseUrl: string,*/
    baseUrlService: BaseUrlService,
    router: Router) {
    this.baseUrl = baseUrlService.getBaseUrl();
    this.http = http;

    this.returnUrl = location.pathname + (location.search > '' ? location.search : '');
    console.log(this.returnUrl);
    this.getAuthenticated(http, this.baseUrl, router);

    this.subscription = translate.activeLangChanged.subscribe(
      (event: { previousValue: string; currentValue: string }) => {
        this.getStudent(this.http, this.baseUrl, event.currentValue);
      }
    );
  }

  ngOnInit() {
    this.getStudent(this.http, this.baseUrl, this.translate.activeLang);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private getAuthenticated(http: HttpClient, baseUrl: string, router: Router) {
    this.notLoaded = true;
    http.get<boolean>(baseUrl + 'api/login/authenticated').subscribe(result => {
      this.authenticated = result;
      if (this.authenticated)
        this.notLoaded = true;
      else
        this.notLoaded = false;
    }, error => {
      console.error(error);
      this.notLoaded = false;
    });
  }

  private getStudent(http: HttpClient, baseUrl: string, language: string) {
    this.notLoaded = true;
    http.get<Student>(baseUrl + 'api/' + language + '/Student').subscribe(result => {
      this.student = result;
      this.notLoaded = false;
    }, error => {
      console.error(error);
      this.notLoaded = false;
    });
  }
}
