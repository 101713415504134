<div class="row">
  <div class="col chalmers-breadcrumb">
    <ng-container *ngFor="let c_step of steps; index as idx">
      <strong *ngIf="stepIdx == idx; else stepSpan">{{ "step" | translate }} {{ c_step }}</strong>
      <ng-template #stepSpan>
        <span>{{ "step" | translate }} {{ c_step }}</span>
      </ng-template>
      <span *ngIf="idx < steps.length - 1">&nbsp;-&nbsp;</span>
    </ng-container>
  </div>
</div>
<ng-content></ng-content>
