import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BaseUrlService {
  getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
  }
  getBaseUrlIDP() {
    return 'https://localhost:5111';
  }
  constructor() { }
}
