<step-tracker [stepIdx]="6" [lastStep]="7">
  <div class="row">
    <div class="col">
      <h2 class="chalmers-h2">{{ "s7-header" | translate }}</h2>
      <h3 class="chalmers-h3">{{ "s7-header1" | translate }}</h3>
      <p>
        {{ "s7-info1" | translate }}
      </p>
      <h3 class="chalmers-h3">{{ "s7-header2" | translate }}</h3>
      <p>
        {{ "s7-info2" | translate }}
      </p>
      <h3 class="chalmers-h3" *ngIf="activeLanguage=='sv'">{{ "s7-header4" | translate }}</h3>
      <p *ngIf="activeLanguage=='sv'">
        {{ "s7-info4" | translate }}
      </p>
      <h3 class="chalmers-h3" *ngIf="activeLanguage=='sv'">{{ "s7-header3" | translate }}</h3>
      <p *ngIf="activeLanguage=='sv'">
        {{ "s7-info3" | translate }}
      </p>
      <ol *ngIf="activeLanguage=='sv'">
        <li>{{ "s7-info3-p1" | translate }}</li>
        <li>{{ "s7-info3-p2" | translate }}</li>
        <li>{{ "s7-info3-p3" | translate }}</li>
      </ol>
      <p *ngIf="activeLanguage=='sv'">
        <a *ngIf="activeLanguage=='sv'" class="btn btn-success chalmers-mr-2" role="button" href="/">{{ "s7-create-new-application" | translate }}</a>
      </p>
      <p>{{ "s7-info3-2" | translate }}</p>
    </div>
  </div>
</step-tracker>
