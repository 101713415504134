import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Arende } from '../../model/arende.model';
import { Ansokan } from '../../model/ansokan.model';
import { TranslateService } from '@ngstack/translate';
import { BaseUrlService } from '../base-url.service';

@Component({
  selector: 'app-ubstep6',
  templateUrl: './ubstep6.component.html',
  styleUrls: ['./ubstep6.component.css']
})
/** ubstep6 component*/
export class Ubstep6Component {
  public arendenummer: string;
  public language: string;

  constructor(
    public translate: TranslateService,
    private http: HttpClient, baseUrlService: BaseUrlService, /*@Inject('BASE_URL') private baseUrl: string,*/
    private router: Router) {
    this.language = translate.activeLang;
    this.http.get<Arendenummer>('api/' + this.translate.activeLang + '/student/senastearendenummer')
      .subscribe(arende => this.arendenummer = arende.arendenummer);
  }
}

export interface Arendenummer {
  arendenummer: string;
}
