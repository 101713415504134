import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

import { TranslateModule, TranslateService } from '@ngstack/translate';

import { StepTrackerComponent } from './step-tracker/step-tracker.component';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { Step5Component } from './step5/step5.component';
import { Step6Component } from './step6/step6.component';
import { Step7Component } from './step7/step7.component';
import { Ubstep1Component } from './ubstep1/ubstep1.component';
import { Ubstep2Component } from './ubstep2/ubstep2.component';
import { Ubstep3Component } from './ubstep3/ubstep3.component';
import { Ubstep4Component } from './ubstep4/ubstep4.component';
import { Ubstep5Component } from './ubstep5/ubstep5.component';
import { Ubstep6Component } from './ubstep6/ubstep6.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { HomeComponent } from './home/home.component';

export function setupTranslateService(service: TranslateService) {
  return () => service.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    StepTrackerComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    Step5Component,
    Step6Component,
    Step7Component,
    Ubstep1Component,
    Ubstep2Component,
    Ubstep3Component,
    Ubstep4Component,
    Ubstep5Component,
    Ubstep6Component,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    TranslateModule.forRoot({
      // options
      activeLang: "sv",
      supportedLangs: ["sv","en"]
    }),
    FormsModule,
    RouterModule.forRoot([    
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: '', component: Step1Component, pathMatch: 'full', outlet: "authenticated" },
    { path: 'step1', component: Step1Component, outlet: "authenticated" },
    { path: 'step2', component: Step2Component, outlet: "authenticated" },
    { path: 'step3', component: Step3Component, outlet: "authenticated" },
    { path: 'step4', component: Step4Component, outlet: "authenticated" },
    { path: 'step5', component: Step5Component, outlet: "authenticated" },
    { path: 'step6', component: Step6Component, outlet: "authenticated" },
    { path: 'step7', component: Step7Component, outlet: "authenticated" },
    { path: 'ub', component: HomeComponent},
    { path: 'ub', component: Ubstep1Component, outlet: "authenticated"},
    { path: 'ub/step1', component: Ubstep1Component, outlet: "authenticated" },
    { path: 'ub/step2', component: Ubstep2Component, outlet: "authenticated" },
    { path: 'ub/step3', component: Ubstep3Component, outlet: "authenticated" },
    { path: 'ub/step4', component: Ubstep4Component, outlet: "authenticated" },
    { path: 'ub/step5', component: Ubstep5Component, outlet: "authenticated" },
    { path: 'ub/step6', component: Ubstep6Component, outlet: "authenticated" },
    { path: 'privacypolicy', component: PrivacyPolicyComponent, pathMatch: 'full'},
    { path: 'privacypolicy', component: PrivacyPolicyComponent, pathMatch: 'full', outlet: "authenticated"}
], {})
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: setupTranslateService,
    deps: [TranslateService],
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
