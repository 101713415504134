import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Kurs } from '../../model/kurs.model';
import { Ansokan } from '../../model/ansokan.model';
import { TranslateService } from '@ngstack/translate';
import { Subscription } from 'rxjs';
import { Tillgodoraknande } from '../../model/tillgodoraknande.model';
import { Termin } from '../../model/termin.model';
import { Utbytesstudier } from '../../model/utbytesstudier';
import { BaseUrlService } from '../base-url.service';

@Component({
  selector: 'app-ubstep3',
  templateUrl: './ubstep3.component.html',
  styleUrls: ['./ubstep3.component.css']
})

export class Ubstep3Component {
  bevisId: string;
  precisering: string;
  kurser: Kurs[];
  tillgodoraknanden: Tillgodoraknande[];
  baseUrl: string;
  http: HttpClient;
  subscription: Subscription;
  notLoaded: boolean;
  terminer: Termin[] = [];
  utbytesstudier: Utbytesstudier;

  public get loaded(): boolean {
    return !this.notLoaded;
  }

  get valdaKurser(): Kurs[] {
    return Ansokan.kurser;
  }

  get sumValdaPoang(): number {
    let result: number;
    result = 0;
    if (Ansokan.kurser.length > 0) {
      result = result + Ansokan.kurser
        .map(kurs => kurs.kursomfattning)
        .reduce((sum, current) => sum + current)
    }
    if (Ansokan.tillgodoraknanden.length > 0) {
      result = result + Ansokan.tillgodoraknanden
        .map(tillgodoraknande => tillgodoraknande.omfattning)
        .reduce((sum, current) => sum + current)
    }
    return result;
  }

  constructor(public translate: TranslateService, http: HttpClient, baseUrlService: BaseUrlService
    /*@Inject('BASE_URL') baseUrl: string*/) {

    this.subscription = translate.activeLangChanged.subscribe(
      (event: { previousValue: string; currentValue: string }) => {
        this.tillgodoraknanden = [];
        this.kurser = [];
        this.kurser.splice(0);
        this.GetKursInformation(this.http, this.baseUrl);
      }
    );

    this.bevisId = Ansokan.beviskombination.id;
    this.http = http;
    this.baseUrl = baseUrlService.getBaseUrl();
  }

  ngOnInit() {
    this.GetKursInformation(this.http, this.baseUrl);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private GetKursInformation(http: HttpClient, baseUrl: string) {
    this.notLoaded = true;
    http.get<Kurs[]>(baseUrl + 'api/' + this.translate.activeLang + '/kurser?beviskombinationUID=' + this.bevisId)
      .subscribe(result => {
        this.kurser = result;
      }, error => {
        console.error(error);
      });
    http.get<Tillgodoraknande[]>(baseUrl + 'api/' + this.translate.activeLang + '/kurser/TGUB?beviskombinationUID=' + this.bevisId)
      .subscribe(result => {
        this.tillgodoraknanden = result;
        this.notLoaded = false;
      }, error => {
        console.error(error);
        this.notLoaded = false;
      });
    http.get<Termin[]>(baseUrl + 'api/' + this.translate.activeLang + '/kurser/terminer')
      .subscribe(result => {
        this.terminer = result;
        this.notLoaded = false;
      }, error => {
        console.error(error);
        this.notLoaded = false;
      });
    http.get<Utbytesstudier>(baseUrl + 'api/' + this.translate.activeLang + '/kurser/ExchangeStudiesPaid')
      .subscribe(result => {
        this.utbytesstudier = result;
        this.notLoaded = false;
      }, error => {
        console.error(error);
        this.notLoaded = true;
      });
  }

  onClick(kurs: Kurs) {
    if (this.isSelected(kurs)) {
      this.unselectKurs(kurs);
    }
    else {
      this.selectKurs(kurs);
    }
  }

  isSelected(kurs: Kurs): boolean {
    return Ansokan.kurser.some(selectedKurs => selectedKurs.id == kurs.id);
  }

  selectKurs(kurs: Kurs): void {
    Ansokan.kurser.push(kurs);
  }

  unselectKurs(kurs: Kurs): void {
    let k = Ansokan.kurser.find(k => k.id == kurs.id);
    Ansokan.kurser.splice(Ansokan.kurser.indexOf(k), 1);
  }

  isSelectedTillgodoraknande(tillgodoraknande: Tillgodoraknande): boolean {
    return Ansokan.tillgodoraknanden.some(selectedTillgodoraknande => selectedTillgodoraknande.id == tillgodoraknande.id);
  }

  onClickTillgodoraknande(tillgodoraknande: Tillgodoraknande) {
    if (this.isSelectedTillgodoraknande(tillgodoraknande)) {
      this.unselectTillgodoraknande(tillgodoraknande);
    }
    else {
      this.selectTillgodoraknande(tillgodoraknande);
    }
  }

  selectTillgodoraknande(tillgodoraknande: Tillgodoraknande): void {
    Ansokan.tillgodoraknanden.push(tillgodoraknande);
  }

  unselectTillgodoraknande(tillgodoraknande: Tillgodoraknande): void {
    let k = Ansokan.tillgodoraknanden.find(t1 => t1.id == tillgodoraknande.id);
    Ansokan.tillgodoraknanden.splice(Ansokan.tillgodoraknanden.indexOf(k), 1);
  }

  selectAlla(): void {
    this.kurser
      .filter(kurs => !this.isSelected(kurs))
      .slice()
      .forEach(kurs => this.selectKurs(kurs));

    this.tillgodoraknanden
      .filter(tillgodoraknande => !this.isSelectedTillgodoraknande(tillgodoraknande))
      .slice()
      .forEach(tillgodoraknande => this.selectTillgodoraknande(tillgodoraknande));
  }

  unselectAlla(): void {
    Ansokan.kurser
      .filter(kurs => this.isSelected(kurs))
      .slice()
      .forEach(kurs => this.unselectKurs(kurs));

    Ansokan.tillgodoraknanden
      .filter(tillgodoraknande => this.isSelectedTillgodoraknande(tillgodoraknande))
      .slice()
      .forEach(tillgodoraknande => this.unselectTillgodoraknande(tillgodoraknande));
  }

  public allaKurserBetalda(): boolean {
    return this.kurser
      .filter(kurs => kurs.larosate == 'Chalmers tekniska högskola' || kurs.larosate == 'Chalmers University of Technology')
      .every(kurs => kurs.betald) ?? this.utbytesstudier.betald;
  }

  public kursBetald(kurs: Kurs): boolean {
    if (kurs.larosate == 'Chalmers tekniska högskola' || kurs.larosate == 'Chalmers University of Technology') {
      return kurs.betald;
    }
    return true;
  }
}
