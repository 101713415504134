import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngstack/translate';
import { Subscription } from 'rxjs';

@Component({
  selector: 'privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacyPolicyComponent {
  
  privacyPolicy: string;
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService
    ) {
      
  this.privacyPolicy = this.translate.get("privacyPolicy");

  this.subscription = translate.activeLangChanged.subscribe(
    (event: { previousValue: string; currentValue: string }) => {
      this.privacyPolicy = this.translate.get("privacyPolicy");
    }
  );

  }
  

  
}
