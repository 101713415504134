import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Ansokan } from '../../model/ansokan.model';

@Component({
  selector: 'app-ubstep4',
  templateUrl: './ubstep4.component.html',
  styleUrls: ['./ubstep4.component.css']
})
/** ubstep4 component*/
export class Ubstep4Component {
  /** steg4 ctor */
  friText: string;
  constructor(private router: Router) {
    this.friText = Ansokan.meddelande;
  }
  onNext() {
    Ansokan.meddelande = this.friText;
    console.debug(JSON.stringify(Ansokan));
    this.router.navigate([{ outlets: { authenticated: ['ub','step5'] } }]);
  }
}
