
<h2 class="chalmers-h2">{{ "welcome" | translate }}</h2>


<div *ngIf="error" class="row">
  <div class="col">
    <div class="alert alert-danger">
      {{error | translate}}
    </div>
  </div>
</div>

<p *ngIf="showUbMessage"> {{ "ub-welcome-message" | translate }} </p>
<p *ngIf="!showUbMessage"> {{ "welcome-message" | translate }} </p>

<div>
  <div class="row" *ngFor="let identityProvider of identityProviders">
    <div class="col">             
      <a href="{{ identityProvider.url }}={{ returnUrl }}" class="login-link">
        <div class="item"><img src="{{ identityProvider.iconUrl }}" alt="Chalmers logotype" /></div>
        <div class="item-center">
          <h3>{{ identityProvider.name | translate }}</h3>
          {{ identityProvider.description | translate }}
        </div>
        <div class="item arrow">
          <i class="bi bi-chevron-right"></i>
        </div>
      </a>
    </div>
  </div>
</div>
<hr>
<div>
  <a href="{{ 'privacy-policy-url' | translate }}" target="_blank">{{ "privacy-policy" | translate }}</a>
</div>

